import { AnyObjectSchema, boolean, object, string } from "yup";
import { setIn, ValidationErrors } from "final-form";
import { getLocalizedValidationMessage } from "./localization";
import { FormsValidationLocalization } from "../types/graphql/generated";

// TODO NEED BE FIX FOR THIS, DO NOT HANDLE ON FE
export const invalidZipUsMsg = "Invalid US zip code";
export const invalidZipCaMsg = "Invalid Canadian postal code";
export const invalidZipPlMsg = "Nieprawidłowy polski kod pocztowy";

/**
 * Additional regex needed on top of Yup's built-in email method, it DOES NOT match for email pattern
 *
 * ^([\x20-\x7E]+)$
 * - Regex for English characters (includes asci 32 ` ` space to 126 `~` tilde)
 * - This helps to prevent users from potentially typing email address like bjørn@example.com
 *
 * (?!.*`)
 * - Negative lookahead to not match on backtick
 * - can add more chars if needed after the backtick in the expression
 */
export const regexEmailHelper = /(?!.*`)^([\x20-\x7E]+)$/gm;

export const regexPhone =
  /^(?!0+$)(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
// https://regexland.com/zip-codes/
export const regexZipUs = /^\d{5}(?:[- ]?\d{4})?$/;
// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s15.html
export const regexZipCa =
  /^(?!.*[DdFfIiOoQqUu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
export const regexZipPl = /^\d{2}-\d{3}/;

export const getLoginSchema = (
  localization?: FormsValidationLocalization | null
) =>
  object().shape({
    email: string()
      .trim()
      .email(getLocalizedValidationMessage("wrongEmailErrorText", localization))
      .matches(regexEmailHelper, {
        excludeEmptyString: true,
        message: getLocalizedValidationMessage(
          "wrongEmailErrorText",
          localization
        ),
      })
      .required(
        getLocalizedValidationMessage("emailRequiredErrorText", localization)
      ),
    password: string()
      .trim()
      .required(
        getLocalizedValidationMessage("passwordRequiredErrorText", localization)
      ),
  });

const getSignupPartial = (
  localization?: FormsValidationLocalization | null
) => ({
  firstName: string().required(
    getLocalizedValidationMessage("fieldRequiredErrorText", localization)
  ),
  lastName: string().required(
    getLocalizedValidationMessage("fieldRequiredErrorText", localization)
  ),
  email: string()
    .trim()
    .email(getLocalizedValidationMessage("wrongEmailErrorText", localization))
    .matches(regexEmailHelper, {
      excludeEmptyString: true,
      message: getLocalizedValidationMessage(
        "wrongEmailErrorText",
        localization
      ),
    })
    .required(
      getLocalizedValidationMessage("emailRequiredErrorText", localization)
    ),
  password: string()
    .trim()
    .required(
      getLocalizedValidationMessage(
        "signupPasswordRequiredErrorText",
        localization
      )
    ),
  terms: boolean().required(),
});

export const getSignupSchema = (
  localization?: FormsValidationLocalization | null
) => object({ ...getSignupPartial(localization) });

export const getSignupWithAgeSchema = (
  localization?: FormsValidationLocalization | null
) =>
  object({
    ...getSignupPartial(localization),
    month: object()
      .shape({
        value: string(),
        label: string(),
      })
      .required(
        getLocalizedValidationMessage("monthRequiredErrorText", localization)
      )
      .nullable(),
    day: object()
      .shape({
        value: string(),
        label: string(),
      })
      .required(
        getLocalizedValidationMessage("dayRequiredErrorText", localization)
      )
      .nullable(),
    year: object()
      .shape({
        value: string(),
        label: string(),
      })
      .required(
        getLocalizedValidationMessage("yearRequiredErrorText", localization)
      )
      .nullable(),
  });

export const getUpdateNameSchema = (
  localization?: FormsValidationLocalization | null
) =>
  object().shape({
    firstName: string()
      .required(
        getLocalizedValidationMessage("requiredErrorText", localization)
      )
      .nullable(),
    lastName: string()
      .required(
        getLocalizedValidationMessage("requiredErrorText", localization)
      )
      .nullable(),
  });

/* https://gist.github.com/manzoorwanijk/5993a520f2ac7890c3b46f70f6818e0a */
export const validateWithYup =
  (schema: AnyObjectSchema) =>
  async (values: unknown): Promise<ValidationErrors | undefined> => {
    try {
      await schema.validate(values, { abortEarly: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const errors = err?.inner?.reduce(
        (
          formError: Record<string, unknown>,
          innerError: { path: string; message: string }
        ) => {
          return setIn(formError, innerError.path, innerError.message);
        },
        {}
      );
      return errors;
    }
    return;
  };

// async field-level validation memoization from the react-final-form docs
// this is needed or the validation will infinitely loop
// https://final-form.org/docs/react-final-form/examples
// https://codesandbox.io/s/wy7z7q5zx5
// eslint-disable-next-line @typescript-eslint/ban-types
export const asyncFieldLevelMemo = (fn: Function) => {
  let lastArg: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  let lastResult: Promise<Function>;
  return (arg: string) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

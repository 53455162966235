import React, { FC } from "react";
import { components, IndicatorProps } from "react-select";
import styled from "styled-components";
import { colors } from "../../styles/variables";
import { ReactSelectDropdownOption } from "./types";

const Caret = styled.svg`
  height: 8.472px;
  width: 17px;
`;

const StyledDropdownIndicator = styled.div<DropdownIndicatorProps>`
  ${Caret} {
    ${({ menuIsOpen }) =>
      menuIsOpen &&
      `
    transform: rotate(180deg);
    margin-bottom: 2px;
  `}
  }
`;

interface DropdownIndicatorProps {
  menuIsOpen?: boolean;
}

export const DropdownIndicator: FC<
  IndicatorProps<ReactSelectDropdownOption, false>
> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDropdownIndicator menuIsOpen={props.selectProps.menuIsOpen}>
        <Caret viewBox="0 0 17 8.472">
          <path
            id="Path_147"
            fill={colors.mediumGray}
            d="M182.524 126.129l-8.136-6.7a1 1 0 1 1 1.272-1.544l6.864 5.653 6.864-5.653a1 1 0 1 1 1.272 1.544z"
            data-name="Path 147"
            transform="translate(-174.024 -117.657)"
          />
        </Caret>
      </StyledDropdownIndicator>
    </components.DropdownIndicator>
  );
};

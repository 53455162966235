import React from "react";
import { HexColor } from "../../types/styles";
import { colors } from "../../styles/variables";

export const CloseIconSvg = ({
  color = colors.gray900,
}: {
  color?: HexColor;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.26703 16L0 14.733L6.73297 8L0 1.26703L1.26703 0L8 6.73297L14.733 0L16 1.26703L9.26703 8L16 14.733L14.733 16L8 9.26703L1.26703 16Z"
      fill={color}
    />
  </svg>
);

import { FC, useContext } from "react";
import { useRouter } from "next/router";
import PostalAddress from "i18n-postal-address";
import styled from "styled-components";
import { colors } from "@pepdirect/v3/styles/variables";
import { LocalizationContext } from "v3/context/localization";
import { TemplateAsComponent } from "@pepdirect/v3/ui/TemplateAsComponent";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import {
  VerifiedAddressFragment,
  VerifyAddressInput,
} from "services/graphql/generated";
import {
  formatCityStateZipByCountry,
  formatFullName,
  formatStreetByCountry,
  FormatCityStateZipByCountry,
} from "@pepdirect/v3/helpers/address";
import { logUnverifiedAddressSelected } from "@pepdirect/helpers/analyticsLogger";
import { errorCodes } from "constants/error";
import commonSt from "../verificationAndConfirmationModal.module.scss";
import st from "./addressVerificationModal.module.scss";

export type VerificationAddrTypes =
  | VerifiedAddressFragment
  | VerifyAddressInput;

interface AddressVerificationModalProps {
  verifiedAddress?: VerifiedAddressFragment | null;
  unverifiedAddress?: VerifyAddressInput | null;
  userConfirm?: (addr: VerificationAddrTypes) => void;
  onClose: () => void;
  loading?: boolean;
  hasInvalidZip?: boolean;
}

interface DisplayedAddressProps {
  addr: VerificationAddrTypes;
  title: string;
  hasInvalidZip?: boolean;
}

const StyledTitle = styled.div<{ hasError: boolean }>`
  font-weight: 700;
  margin-bottom: 10px;
  ${({ hasError }) => hasError && `color: ${colors.red};`}
`;

const StyledError = styled.span`
  font-weight: 700;
  color: ${colors.red};
`;

const CityAndStateWithZipError: FC<FormatCityStateZipByCountry> = ({
  city,
  countryFormat,
  state,
  zip,
}) => {
  const address = new PostalAddress();
  address
    .setCity(city || "")
    .setState(state || "")
    // this creates a template string so that we can bold the zip below
    .setPostalCode(`\${zip}` || "")
    .setFormat({ country: countryFormat || "US" });

  return (
    <TemplateAsComponent
      template={address.toString()}
      map={{ zip: <StyledError>{zip}</StyledError> }}
    />
  );
};

const DisplayedAddress: FC<DisplayedAddressProps> = ({
  addr,
  title,
  hasInvalidZip,
}) => {
  const {
    city,
    countryCode: countryFormat,
    firstName,
    lastName,
    line1,
    state,
    zip,
    line2,
  } = addr;
  const fullName = formatFullName({ firstName, lastName, countryFormat });
  const streetAddress = formatStreetByCountry({ line1, line2, countryFormat });
  const cityStateZip = formatCityStateZipByCountry({
    city,
    state,
    zip,
    countryFormat,
  });

  return (
    <div className={st.address}>
      <StyledTitle hasError={!!hasInvalidZip}>{title}</StyledTitle>
      <div className={st.name}>{fullName}</div>
      <div>{streetAddress}</div>
      {hasInvalidZip ? (
        <CityAndStateWithZipError
          city={city}
          countryFormat={countryFormat}
          state={state}
          zip={zip}
        />
      ) : (
        <div>{cityStateZip}</div>
      )}
    </div>
  );
};

export const AddressVerificationModal: FC<AddressVerificationModalProps> = ({
  verifiedAddress,
  unverifiedAddress,
  userConfirm = () => {},
  onClose,
  loading,
  hasInvalidZip,
}) => {
  const { localization } = useContext(LocalizationContext);
  const { pathname } = useRouter();
  const isCheckout = !pathname.includes("dashboard");

  const {
    shippingAddressText = "Shipping Address",
    recommendChangesText = "Our address verification system recommends the following changes based on the information provided by our delivery service. Please choose from the following",
    invalidZipCodeText = "Invalid Zip Code",
    enteredAddressText = "Entered Address",
    editEnteredAddressText = "Edit Entered Address",
    keepEnteredAddressText = "Keep Entered Address",
    suggestedAddressText = "Suggested Address",
    useSuggestedAddressText = "Use Suggested Address",
    goBackText = "Go Back",
    confirmSuggestedAddressText = "Our address verification system recommends the following changes based on the information provided by our delivery service. Please confirm the suggested address below is correct",
    checkoutSpecificConfirmSuggestedText = " to complete your order",
    notAbleToVerifyShippingAddressText = "We were not able to verify the shipping address that was provided.",
    doYouWantToProceedText = "Do you want to proceed with the shipping address entered?",
    editText = "Edit",
    verifyText = "Verify",
    confirmText = "Confirm",
  } = localization?.portal?.errors?.addressVerification || {};

  if (!(verifiedAddress || unverifiedAddress)) {
    // something went wrong
    return <div>{errorCodes["somethingWentWrong"]}</div>;
  }

  if (unverifiedAddress && verifiedAddress) {
    return (
      <div className={commonSt.body}>
        <div>
          <Heading level="h2" size="xl">
            {hasInvalidZip ? editText : verifyText} {shippingAddressText}
          </Heading>

          <p>{recommendChangesText}:</p>

          <div className={st.addressesWrapper}>
            <div className={st.addresses}>
              <div>
                <DisplayedAddress
                  addr={unverifiedAddress}
                  title={
                    hasInvalidZip ? invalidZipCodeText : enteredAddressText
                  }
                  hasInvalidZip={hasInvalidZip}
                />

                {hasInvalidZip ? (
                  <Button type="cancel" onClick={onClose} fullWidth>
                    {editEnteredAddressText}
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    type="tertiary"
                    loading={loading}
                    onClick={() => {
                      logUnverifiedAddressSelected();
                      userConfirm(unverifiedAddress);
                    }}
                    fullWidth
                  >
                    {keepEnteredAddressText}
                  </Button>
                )}
              </div>

              <div>
                <DisplayedAddress
                  addr={verifiedAddress}
                  title={suggestedAddressText}
                />

                <Button
                  htmlType="submit"
                  type="secondary"
                  loading={loading}
                  onClick={() => userConfirm(verifiedAddress)}
                  fullWidth
                >
                  {useSuggestedAddressText}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className={st.buttons}>
          <Button type="cancel" onClick={onClose}>
            {goBackText}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={commonSt.body}>
      <div>
        <Heading level="h2" size="xl">
          {hasInvalidZip ? editText : verifyText} {shippingAddressText}
        </Heading>

        {verifiedAddress && !unverifiedAddress && (
          <>
            <p>
              {confirmSuggestedAddressText}
              {isCheckout ? checkoutSpecificConfirmSuggestedText : ""}.
            </p>

            <DisplayedAddress
              addr={verifiedAddress}
              title={suggestedAddressText}
            />
          </>
        )}

        {unverifiedAddress && !verifiedAddress && (
          <>
            <p className={st.unverifiedText}>
              {notAbleToVerifyShippingAddressText}
              {!hasInvalidZip && " " + doYouWantToProceedText}
            </p>

            <DisplayedAddress
              addr={unverifiedAddress}
              title={hasInvalidZip ? invalidZipCodeText : enteredAddressText}
              hasInvalidZip={hasInvalidZip}
            />
          </>
        )}
      </div>

      <div className={st.buttons}>
        <Button type="cancel" onClick={onClose}>
          {hasInvalidZip ? editEnteredAddressText : goBackText}
        </Button>

        {!hasInvalidZip && (
          <Button
            htmlType="submit"
            type="secondary"
            loading={loading}
            onClick={() => {
              if (unverifiedAddress) logUnverifiedAddressSelected();
              userConfirm(
                // one of these must exist at this point
                (verifiedAddress || unverifiedAddress) as VerificationAddrTypes
              );
            }}
          >
            {confirmText}
          </Button>
        )}
      </div>
    </div>
  );
};

import { validationErrorsEnglish } from "../constants";
import { FormsValidationLocalization } from "../types/graphql/generated";

interface StringMap {
  [key: string]: string | number;
}

/**
 * Used to for simple string interpolation
 * Ex. usage: "Hi ${firstName} ${lastName}"
 * For complex string interpolation such as adding links to a sentence, use TemplateAsComponent
 */
export const parseTemplateAsString = (template: string, map: StringMap) => {
  return template.replace(/\$\{.+?}/g, (match) => {
    const key = match.substring(2, match.length - 1).trim();
    return map[key].toString();
  });
};

export interface HandlePluralityInTemplate {
  count: number | null | undefined;
  singular: string;
  plural: string;
  zero?: string;
  many?: string;
  fractional?: string;
  map: StringMap;
}

export const handlePluralityInTemplate = ({
  count,
  singular,
  plural,
  zero,
  many,
  fractional,
  map = {},
}: HandlePluralityInTemplate) => {
  let template = "";

  if (count === undefined || count === null) {
    template = plural;
  } else if (count === 0) {
    template = zero ?? plural;
  } else if (count === 1) {
    template = singular;
  } else if (count > 1 && count < 5) {
    template = plural;
  } else if (count >= 5) {
    template = many ?? plural;
  } else if (count % 1 !== 0) {
    // For fractional numbers
    template = fractional ?? singular;
  }
  return parseTemplateAsString(template, map);
};

export const getLocalizedValidationMessage = (
  localizationKey: keyof FormsValidationLocalization,
  localization?: FormsValidationLocalization | null
): string =>
  localization?.[localizationKey] ||
  validationErrorsEnglish[localizationKey] ||
  "";
